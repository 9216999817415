<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-21 18:51:25
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-09-14 17:42:49
-->
<template>
  <div class="index-bar-wrapper">
    <div class="index-bar-content" v-for="(item, index) in dataList" :key="index">
      <div class="index-title">{{ item.letter.toUpperCase() }}</div>
      <div class="index-lists">
        <div
          class="bar-list-wrapper"
          @click="handleClick(child)"
          v-for="(child, index) in item.children"
          :key="index"
        >
          <div class="bar-list">
            <div class="name">{{ child.name }}</div>
            <div class="chose" v-if="child.checked">
              <van-icon name="success" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "vant";
import { ref, reactive } from "vue";
export default {
  components: {
    [Icon.name]: Icon,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const dataLists = reactive(props.dataList);
    const currentSelectList = ref([]);
    // 获取再次默认选中值
    dataLists.forEach((item) => {
      if (item.children && item.children.length) {
        let defaultSelect = [];
        defaultSelect = item.children.filter((ele) => {
          return ele.checked;
        });
        currentSelectList.value.push(...defaultSelect);
      }
    });
    const handleClick = (item) => {
      item.checked = !item.checked;
      if (item.checked) {
        if (!props.multiSelect && currentSelectList.value.length) {
          // 如果不是多选
          currentSelectList.value = [];
          currentSelectList.value.push(item);
          cancelOtherChecked(item);
        } else {
          filterSelectList(item);
        }
      } else {
        deletCurrentItem(item);
      }
      context.emit("getCurrentSelect", currentSelectList.value);
    };
    // 移除当前选中列表
    const deletCurrentItem = () => {
      if (currentSelectList.value.length) {
        currentSelectList.value = currentSelectList.value.filter((item) => {
          return item.checked;
        });
      }
    };
    // 检测 选中数组里面是否有当前值
    const filterSelectList = (item) => {
      if (currentSelectList.value.length) {
        const flag = currentSelectList.value.some((ele) => {
          return item.id == ele.id;
        });
        if (!flag) {
          currentSelectList.value.push(item);
        }
      } else {
        currentSelectList.value.push(item);
      }
    };
    // 如果不为多选，清除其他checked为真的值
    const cancelOtherChecked = (items) => {
      dataLists.forEach((item) => {
        if (item.children && item.children.length) {
          item.children.forEach((ele) => {
            if (items.id !== ele.id) {
              ele.checked = false;
            }
          });
        }
      });
    };
    // 向外抛出当前选中
    const getCurrentSelectList = () => {
      return currentSelectList.value;
    };
    return {
      handleClick,
      getCurrentSelectList,
    };
  },
};
</script>

<style lang="less" scoped>
.index-bar-wrapper {
  margin-top: 36px;
  .index-bar-content {
    .index-title {
      padding: 19px 32px;
      background: #f8f8f8;
      font-size: 36px;
      color: #999;
    }
    .index-lists {
      .bar-list-wrapper {
        padding: 0px 32px;
        background: #fff;
        .bar-list {
          padding: 35px 0px;
          font-size: 30px;
          color: #333;
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: space-between;
          .chose {
            font-size: 27px;
            color: #1678ff;
          }
        }
        &:last-child {
          .bar-list {
            border-bottom: inherit;
          }
        }
      }
    }
  }
}
</style>
