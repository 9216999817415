<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-12 17:55:41
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-05-12 18:05:17
-->
<template>
  <div class="business-progress">
    <div class="progress">
      <van-slider bar-height="4" v-model="sliderNumber" />
    </div>
    <span class="number">{{ sliderNumber + "%" }}</span>
  </div>
</template>
<script>
import { Slider } from "vant";
import { ref } from "vue";
export default {
  components: {
    [Slider.name]: Slider,
  },
  props: {
    progressNumber: {
      type: Number,
      default: 80,
    },
  },
  setup() {
    const sliderNumber = ref(0);
    const getCurrentNumber = () => {
      return sliderNumber;
    };
    return {
      sliderNumber,
      getCurrentNumber,
    };
  },
};
</script>
<style lang="less" scoped>
.business-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .progress {
    flex: 1;
    margin-right: 32px;
  }
  .number {
    display: inline-block;
    width: 80px;
    font-size: 28px;
  }
}
</style>