<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-12 15:03:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-07 20:39:31
-->
<template>
  <div class="business-wrapper">
    <my-nav title="商机类型"></my-nav>
    <div class="business-lists">
      <div
        @click="changeBusiness(item.type)"
        v-for="item in navList"
        :key="item.type"
        :class="['business-item mt25', `${currentBusiness == item.type ? 'active' : ''}`]"
      >
        <div class="business-item-wrapper">
          <p class="business-title">{{ item.title }}</p>
          <div class="business-message">{{ item.detail }}</div>
        </div>
        <div v-if="currentBusiness === item.type" class="business-icon">
          <i class="iconfont hxb-icon_gougou"></i>
        </div>
      </div>
    </div>
    <div class="business-content-wrapper">
      <examination-business
        v-if="currentBusiness === 1"
        ref="examination"
        @changeChecked="changeChecked"
      ></examination-business>
      <app-business
        v-else-if="currentBusiness === 2"
        ref="appBusiness"
        @getAppBusiness="changeChecked"
      ></app-business>
    </div>
    <div class="button-group">
      <button-group
        :successSubmit="successSubmit"
        @handleButtonLeft="handleButtonLeftOther"
        @handleButtonRight="handleButtonRight"
        :loadingLeft="loadingLeft"
        :successSubmitRight="buttonLeft"
        :successSubmitLeft="buttonLeft"
      ></button-group>
    </div>
  </div>
</template>
<script>
import myNav from "@/components/nav";
import { businessList } from "@/utils/const";
import examinationBusiness from "./examinationBusiness";
import appBusiness from "./appBusiness";
import buttonGroup from "@/components/buttonGroup";
import { addCustomerBusiness } from "@/api";
import { Icon, Toast } from "vant";
import { reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Business",
  components: {
    myNav,
    appBusiness,
    buttonGroup,
    examinationBusiness,
    [Icon.name]: Icon,
  },
  setup() {
    const router = useRouter();
    const navList = ref(businessList);
    const currentBusiness = ref(0);
    const successSubmit = ref(false);
    const loadingLeft = ref(false);
    const examination = ref(null);
    const appBusiness = ref(null);
    const checkeObj = reactive({
      buttonLeft: true,
      buttonRight: false,
    });
    const changeBusiness = (type) => {
      checkeObj.buttonLeft = true;
      currentBusiness.value = type;
    };
    const handleButtonLeft = (cb) => {
      let getParams = {};
      if (currentBusiness.value === 1) {
        getParams = examination.value.getCurrentParams();
      } else {
        getParams = appBusiness.value.retrunCurrentParams();
      }
      loadingLeft.value = true;
      addCustomerBusiness(getParams).then((res) => {
        if (res && res.body) {
          loadingLeft.value = false;
          cb(res.body);
        }
      });
    };
    const handleButtonRight = () => {
      let reportMessage = "";
      if (currentBusiness.value === 1) {
        reportMessage = examination.value.retrunReportMessage();
      } else {
        reportMessage = appBusiness.value.retrunReportMessage();
      }
      // 提交转为订单
      handleButtonLeft((res) => {
        if (res) {
          localStorage.setItem("businessId", res); // 开通App需要
          router.push({
            path: "/order",
            query: {
              businessId: res,
              type: currentBusiness.value, // 1考籍类商机 2 app 商机
              reportMessage: reportMessage,
            },
          });
        }
      });
    };
    const handleButtonLeftOther = () => {
      handleButtonLeft((res) => {
        if (res) {
          Toast({
            message: "提交成功!",
            onClose: () => {
              router.push({
                path: "/client",
              });
            },
          });
        }
      });
    };
    const changeChecked = (type) => {
      checkeObj.buttonLeft = type;
    };
    return {
      changeBusiness,
      navList,
      examination,
      loadingLeft,
      currentBusiness,
      successSubmit,
      changeChecked,
      handleButtonLeftOther,
      handleButtonRight,
      appBusiness,
      ...toRefs(checkeObj),
    };
  },
};
</script>
<style lang="less" scoped>
.business-wrapper {
  min-height: 100%;
  padding: 40px 32px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  .business-lists {
    .business-item {
      background-color: #fff;
      border-radius: 24px;
      padding: 32px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .business-item-wrapper {
        .business-title {
          font-size: 30px;
          font-weight: bold;
          color: #333;
        }
        .business-message {
          margin-top: 16px;
          font-size: 24px;
          color: #999;
        }
      }
      .business-icon {
        margin-left: 5px;
        color: #1678ff;
        font-weight: bold;
        i {
          font-size: 40px;
        }
      }
    }
    .active {
      border: 2px solid #1678ff;
      .business-item-wrapper {
        .business-title,
        .business-message {
          color: #1678ff;
        }
      }
    }
  }
  .button-group {
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 555;
    background: #fff;
    padding: 32px;
    box-sizing: border-box;
  }
  .business-content-wrapper {
    padding-bottom: 150px;
  }
}
</style>
