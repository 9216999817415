<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-12 15:59:14
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-09-15 16:15:46
-->
<template>
  <div class="examination-business">
    <my-nav title="选择商品"></my-nav>
    <div class="choose-good mt25">
      <van-form @failed="onFailed">
        <van-field
          v-model="examination.goods"
          label="商品大类"
          name="pattern"
          readonly
          input-align="right"
          right-icon="arrow"
          @click="isShowGoodsBig = true"
          clickable
          required
          placeholder="请选择"
        />
        <van-field
          v-if="data.signType != 3 && data.signType != 4"
          v-model="examination.provinceName"
          label="省份"
          readonly
          name="pattern"
          @click="isShowProvince = true"
          input-align="right"
          right-icon="arrow"
          clickable
          required
          placeholder="请选择"
        />
        <van-field
          v-if="data.signType != 3 && data.signType != 4"
          v-model="examination.schoolName"
          label="主考院校"
          input-align="right"
          name="pattern"
          @click="isShowCollege = true"
          right-icon="arrow"
          clickable
          readonly
          required
          placeholder="请选择"
        />
        <van-field
          v-model="examination.goodsNoList"
          label="专业"
          name="pattern"
          input-align="right"
          readonly
          @click="isShowProfess = true"
          right-icon="arrow"
          clickable
          required
          placeholder="请选择"
        />
        <van-field name="switch" input-align="right" required label="目前销售阶段">
          <template #input>
            <div>
              <my-status-list
                :statusList="statusList"
                @getStatusList="getStatusList"
              ></my-status-list>
            </div>
          </template>
        </van-field>
        <van-field name="switch" required label="预计销售成功率">
          <template #input>
            <div class="business-odds">
              <my-progress ref="progress"></my-progress>
            </div>
          </template>
        </van-field>
      </van-form>
    </div>
    <div class="other-actions-sheet" v-if="isShowGoodsBig">
      <van-action-sheet
        :show="isShowGoodsBig"
        title="商品大类"
        :actions="goodsBigTypeLists"
        @cancel="isShowGoodsBig = false"
        @select="getGoodsType"
      />
    </div>
    <div class="other-actions-sheet inde-bar-other" v-if="isShowProvince">
      <van-action-sheet
        :show="isShowProvince"
        title="省份"
        :style="{ height: '80%' }"
        @cancel="cancelMyIndexBar('province')"
      >
        <my-index-bar :dataList="provinceList" ref="myIndexBar"></my-index-bar>
      </van-action-sheet>
    </div>
    <div class="other-actions-sheet inde-bar-other" v-if="isShowCollege">
      <van-action-sheet
        :show="isShowCollege"
        title="院校"
        :style="{ height: '80%' }"
        @cancel="cancelMyIndexBar('college')"
      >
        <my-index-bar :dataList="currentCollegeList" ref="myIndexBar"></my-index-bar>
      </van-action-sheet>
    </div>
    <div class="other-actions-sheet inde-bar-other" v-if="isShowProfess">
      <van-action-sheet
        :show="isShowProfess"
        title="专业"
        :style="{ height: '80%' }"
        @cancel="cancelMyIndexBar('profess')"
      >
        <my-index-bar :dataList="professionalList" @getCurrentSelect="getProfessional" ref="myIndexBar"></my-index-bar>
      </van-action-sheet>
    </div>
  </div>
</template>
<script>
import myNav from "@/components/nav";
import { Form, Field, ActionSheet } from "vant";
import { reactive, ref, toRefs, watch } from "vue";
import myStatusList from "@/components/statusList";
import myProgress from "@/components/myProgress";
import myIndexBar from "@/components/indexBar";
import { getBusinessTree, getExaminationBySchool } from "@/api";
import { pySegSort, matchLabel } from "@/utils/commUtil";
import { businessStatusList, goodTypeList, goodsBigTypeList, degreeList } from "@/utils/const";
export default {
  components: {
    myNav,
    myStatusList,
    myIndexBar,
    [Form.name]: Form,
    [Field.name]: Field,
    [ActionSheet.name]: ActionSheet,
    myProgress,
  },
  setup(props, context) {
    const params = reactive({
      data: {
        signType: 1,
      },
    });
    const statusList = ref(businessStatusList);
    const goodTypeLists = ref(goodTypeList);
    const goodsBigTypeLists = ref(goodsBigTypeList);
    const businessTreesList = ref([]);
    const progress = ref(null);
    const modelStatus = reactive({
      // 组件弹窗控制值
      isShowGoodsBig: false,
      isShowProvince: false,
      isShowCollege: false,
      isShowProfess: false,
      isShowOtherModel: false,
    });
    const state = reactive({
      examination: {},
    });
    const pattern = (val) => {
      return val !== "";
    };
    const myIndexBar = ref(null); // 当前省份组件
    const provinceList = ref([]); // 省份列表
    let provinceSelectList = ref([]); // 当前省份选中值
    let currentCollegeList = ref([]); // 获取当前学院列表
    let getCurrentCollegeList = ref([]); // 获取学院选中值
    let professionalList = ref([]); // 专业列表
    let getProfessionalList = ref([]); // 获取专业选取列表
    const checkedList = reactive({
      getcurrentStatus: -1,
      getcurrentPrefess: professionalList.value,
    });
    // 获取结构树
    const getBusinessTrees = () => {
      getBusinessTree(1).then((res) => {
        if (res && res.body) {
          businessTreesList.value = res.body;
        }
      });
    };

    getBusinessTrees();
    // 专业名称
    const getExaminationBySchools = (provinceId, schoolId) => {
      getExaminationBySchool({
        provinceId: provinceId,
        schoolId: schoolId,
        type: params.data.signType,
      }).then((res) => {
        if (res && res.body) {
          professionalList.value = pySegSort(res.body);
        }
      });
    };
    const getProvinceList = () => {
      businessTreesList.value.forEach((item) => {
        if (params.data.signType == item.id) {
          if (params.data.signType != 3 && params.data.signType != 4) {
            provinceList.value = pySegSort(item.children);
          } else {
            professionalList.value = pySegSort(item.children);
          }
        }
      });
    };
    const cancelMyIndexBar = (type) => {
      // 关闭省份组件，获取当前省份选中值
      if (type === "province") {
        provinceSelectList.value = myIndexBar.value.getCurrentSelectList();
        modelStatus.isShowProvince = false;
        if (provinceSelectList.value.length) {
          state.examination.provinceName = provinceSelectList.value[0].name;
          // 清空下级数据
          getProfessionalList.value = [];
          currentCollegeList.value = [];
          state.examination.schoolName = "";
          state.examination.goodsNoList = "";
        } else {
          state.examination.provinceName = "";
        }
      } else if (type === "college") {
        getCurrentCollegeList.value = myIndexBar.value.getCurrentSelectList();
        modelStatus.isShowCollege = false;
        if (getCurrentCollegeList.value.length) {
          state.examination.schoolName = getCurrentCollegeList.value[0].name;
          // 清空下级
          state.examination.goodsNoList = "";
        } else {
          state.examination.schoolName = "";
        }
      } else if (type === "profess") {
        getProfessionalList.value = myIndexBar.value.getCurrentSelectList();
        modelStatus.isShowProfess = false;
        if (getProfessionalList.value.length) {
          state.examination.goodsNoList =
            getProfessionalList.value.length > 1
              ? `${getProfessionalList.value[0].name}等`
              : getProfessionalList.value[0].name;
          checkedList.getcurrentPrefess = getProfessionalList.value;
        } else {
          state.examination.goodsNoList = "";
        }
      }
    };
    watch(
      params.data,
      () => {
        getProvinceList();
      },
      { deep: true }
    );
    watch(provinceSelectList, (val) => {
      // 监听省份组件, 如果有值，再次进入默认选中, 如果没有值，下级数组值为空
      if (val.length) {
        provinceList.value.forEach((item) => {
          if (item.children.length) {
            item.children.forEach((ele) => {
              if (ele.id === val.id) {
                ele.checked = val.checked;
              }
            });
          }
        });
        // 获取省份下的学院
        val.forEach((item) => {
          if (item.children && item.children.length) {
            currentCollegeList.value = pySegSort(item.children);
          }
        });
      } else {
        provinceList.value.forEach((item) => {
          if (item.children.length) {
            item.children.forEach((ele) => {
              ele.checked = false;
            });
          }
        });
        currentCollegeList.value = [];
        professionalList.value = [];
        state.examination.schoolName = "";
        state.examination.goodsNoList = "";
        checkedList.getcurrentPrefess = [];
      }
    });
    watch(getCurrentCollegeList, (val) => {
      // 监听学院,获取专业
      if (val.length && provinceList.value.length) {
        let provinceId = provinceSelectList.value[0].id;
        getExaminationBySchools(provinceId, val[0].id);
      } else {
        state.examination.goodsNoList = "";
        professionalList.value = [];
      }
    });
    const getGoodsType = (item) => {
      // 选择商品大类值
      for (let i in state.examination) {
        // 改变商品类型时后面选择清空
        state.examination[i] = "";
      }
      provinceSelectList.value = [];
      state.examination.goods = item.name;
      params.data.signType = item.type;
      modelStatus.isShowGoodsBig = false;
    };
    const getStatusList = (item) => {
      state.status = item.type;
      checkedList.getcurrentStatus = item.type;
    };
    // 当专业或者其他考证类型并且目前销售阶段有值时，按钮可以点击
    watch(checkedList, (val) => {
      if (val.getcurrentStatus !== -1 && val.getcurrentPrefess.length) {
        context.emit("changeChecked", false);
      } else {
        context.emit("changeChecked", true);
      }
    });
    // 返回报考信息
    const retrunReportMessage = () => {
      return `${state.examination.goods ? state.examination.goods + "·" : ""}${
        state.examination.provinceName
          ? state.examination.provinceName +
            `${state.examination.schoolName || state.examination.goodsNoList ? "·" : ""}`
          : ""
      }${
        state.examination.schoolName
          ? state.examination.schoolName + `${state.examination.goodsNoList ? "·" : ""}`
          : ""
      }${state.examination.goodsNoList ? state.examination.goodsNoList : ""} ${params.data.signType!==3 && params.data.signType!==4 ? `·${matchLabel(degreeList, 'type', 'name', getProfessionalList.value[0].level) }`: '' }`;
    };
     // 获取专业值
     const getProfessional = (item) => {
       console.log(item)
       if(!item.length) {
          context.emit("changeChecked", true);
       }
     }
    // 返回当前值
    const getCurrentParams = () => {
      let goods = getProfessionalList.value.map((item) => {
        return item.id;
      });
      let totalParams = {
        provinceName: state.examination.provinceName,
        schoolName: state.examination.schoolName,
        customerId: localStorage.getItem("customerId"),
        goodsNoList: goods,
        description: `${getProfessionalList.value[0].name}考籍`,
        signType: params.data.signType,
        status: state.status,
        successPercent: Number(progress.value.getCurrentNumber().value),
        type: 1, // 学籍
      };
      return totalParams;
    };
    return {
      pattern,
      statusList,
      goodTypeLists,
      goodsBigTypeLists,
      getGoodsType,
      provinceList,
      myIndexBar,
      retrunReportMessage,
      progress,
      getStatusList,
      professionalList,
      currentCollegeList,
      getCurrentParams,
      cancelMyIndexBar,
      getProfessional,
      ...toRefs(state),
      ...toRefs(modelStatus),
      ...toRefs(params),
      // ...toRefs(otherMessage),
    };
  },
};
</script>
<style lang="less" scoped>
.examination-business {
  margin-top: 48px;
  .choose-good {
    background: #fff;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 24px;
    overflow: hidden;
    & > div:last-child {
      border: none;
    }
    .step-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .business-odds {
      width: 100%;
    }
  }
}
</style>
