<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-12 15:59:14
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-09-08 17:43:36
-->
<template>
  <div class="examination-business">
    <my-nav title="选择商品"></my-nav>
    <div class="choose-good mt25">
      <van-form @failed="onFailed">
        <van-field
          v-model="state.goods"
          label="商品大类"
          readonly
          right-icon="arrow"
          input-align="right"
          clickable
          @click="isShowGoodsBig = true"
          required
          placeholder="请选择"
        />
        <van-field
          v-model="state.provinceName"
          v-if="currentGoodsType != 3 && currentGoodsType != 4"
          label="省份"
          readonly
          input-align="right"
          @click="isShowProvinve = true"
          right-icon="arrow"
          clickable
          required
          placeholder="请选择"
        />
        <van-field
          v-model="state.major"
          label="专业"
          input-align="right"
          @click="isShowMajor = true"
          right-icon="arrow"
          clickable
          readonly
          required
          placeholder="请选择"
        />
        <van-field name="switch" input-align="right" readonly required label="商品类型">
          <template #input>
            <div>
              <my-status-list
                :statusList="courseLists"
                @getStatusList="getMajorType"
              ></my-status-list>
            </div>
          </template>
        </van-field>
        <van-field
          class="add-major"
          v-if="state.majorType == 2"
          name="switch"
          right-icon="arrow"
          required
          readonly
          :border="false"
          @click="isShowCourse = true"
          label="添加课程"
        >
          <template #input>
            <div class="step-wrapper"></div>
          </template>
        </van-field>
        <div class="add-project-wrapper" v-if="state.majorType == 2">
          <div class="project-item" v-for="item in currentChoseCourseList" :key="item.id">
            {{ item.name }}
            <van-icon name="cross" @click="deleteProject(item)" />
          </div>
        </div>
        <van-field name="switch" input-align="right" required label="目前销售阶段">
          <template #input>
            <div>
              <my-status-list
                :statusList="statusList"
                @getStatusList="getCurrntStep"
              ></my-status-list>
            </div>
          </template>
        </van-field>
        <van-field name="switch" required label="预计销售成功率">
          <template #input>
            <div class="business-odds">
              <my-progress ref="progress"></my-progress>
            </div>
          </template>
        </van-field>
      </van-form>
    </div>

    <div class="other-actions-sheet" v-if="isShowGoodsBig">
      <van-action-sheet
        :show="isShowGoodsBig"
        title="商品大类"
        :actions="goodsBigTypeLists"
        @select="getGoodsType"
        @cancel="isShowGoodsBig = false"
      />
    </div>
    <div class="other-actions-sheet inde-bar-other" v-if="isShowProvinve">
      <van-action-sheet
        :show="isShowProvinve"
        title="省份"
        :style="{ height: '80%' }"
        @cancel="isShowProvinve = false"
      >
        <my-index-bar
          :dataList="provinceList"
          @getCurrentSelect="getCurrentProvinceSelect"
        ></my-index-bar>
      </van-action-sheet>
    </div>
    <div class="other-actions-sheet inde-bar-other" v-if="isShowMajor">
      <van-action-sheet
        :show="isShowMajor"
        title="专业"
        :style="{ height: '80%' }"
        @cancel="isShowMajor = false"
      >
        <my-index-bar
          :dataList="professionalList"
          @getCurrentSelect="getCurrentProfessional"
        ></my-index-bar>
      </van-action-sheet>
    </div>
    <div class="other-actions-sheet inde-bar-other" v-if="isShowCourse">
      <van-action-sheet
        :show="isShowCourse"
        title="课程"
        :style="{ height: '80%' }"
        @cancel="isShowCourse = false"
      >
        <my-index-bar
          multiSelect
          :dataList="courseDataList"
          @getCurrentSelect="getCurrentCourse"
        ></my-index-bar>
      </van-action-sheet>
    </div>
  </div>
</template>
<script>
import myNav from "@/components/nav";
import { Form, Field, ActionSheet, Icon } from "vant";
import { ref, reactive, toRefs, watch } from "vue";
import myIndexBar from "@/components/indexBar";
import { pySegSort } from "@/utils/commUtil";
import { getBusinessTree, getAppMajorAllCourse } from "@/api";
import myStatusList from "@/components/statusList";
import myProgress from "@/components/myProgress";
import {
  businessStatusList,
  courseList,
  goodTypeList,
  goodsBigTypeList,
} from "@/utils/const";
export default {
  components: {
    myNav,
    myStatusList,
    [Form.name]: Form,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    myProgress,
    myIndexBar,
  },
  setup(props, context) {
    const statusList = ref(businessStatusList);
    const courseLists = ref(courseList);
    const goodTypeLists = ref(goodTypeList);
    const goodsBigTypeLists = ref(goodsBigTypeList);
    const currentGoodsType = ref(-1);
    const provinceList = ref([]); // 省份
    const getCurrentProvinceList = ref([]);
    const professionalList = ref([]); // 专业列表
    const getCurrentProfessionalList = ref([]); // 当前选择专业
    const courseDataList = ref([]); //课程列表
    const currentChoseCourseList = ref([]); // 当前选择课程列表
    const progress = ref(null);
    const modelShowList = reactive({
      isShowGoodsBig: false,
      isShowProvinve: false,
      isShowMajor: false,
      isShowCourse: false,
    });
    const state = reactive({
      majorType: 2,
    });
    const pattern = (val) => {
      return val !== "";
    };
    const currentParamsList = reactive({
      // // 必填信息是否有值
      // getProfessList: getCurrentProfessionalList.value, //专业必填
      // currentMajor: state.majorType, // 商品类型
      // currentChoseCourse: currentChoseCourseList.value, // 选择课程列表
      // currentStep: state.step, // 当前销售阶段
    });
    const businessTreesList = ref([]);
    // 获取结构树
    const getBusinessTrees = () => {
      getBusinessTree(2).then((res) => {
        if (res && res.body) {
          businessTreesList.value = res.body;
        }
      });
    };
    getBusinessTrees();
    const getGoodsType = (item) => {
      modelShowList.isShowGoodsBig = false;
      state.goods = item.name;
      currentGoodsType.value = item.type;
      professionalList.value = [];
      getCurrentProfessionalList.value = [];
      currentParamsList.getProfessList = [];
      getCurrentProvinceList.value = [];
      courseDataList.value = [];
      state.provinceName = "";
      state.major = "";
      // 清除省份默认选中
      if (provinceList.value.length) {
        provinceList.value.forEach((item) => {
          item.checked = false;
        });
      }
    };
    const getCurrentProvinceSelect = (arr) => {
      getCurrentProvinceList.value = arr;
      // 获取省份值
      if (arr.length) {
        state.provinceName = arr[0].name;
        // 清除下级数据
        state.major = "";
        getCurrentProfessionalList.value = []; // 清空当前选中专业列表
        currentChoseCourseList.value = []; // 清空当前课程列表
      }
    };
    // 获取商品类型
    const getMajorType = (item) => {
      console.log(item);
      state.majorType = item.type;
      currentParamsList.currentMajor = item.type;
    };
    // 获取阶段
    const getCurrntStep = (item) => {
      state.step = item.type;
      currentParamsList.step = item.type;
    };
    const getCurrentProfessional = (arr) => {
      courseDataList.value = [];
      currentChoseCourseList.value = [];
      getCurrentProfessionalList.value = arr;
      currentParamsList.getProfessList = arr;
      if (arr.length) {
        state.major = arr[0].name;
      }
    };
    const getAppMajorAllCourseList = (id) => {
      getAppMajorAllCourse({
        majorNo: id,
      }).then((res) => {
        if (res && res.body) {
          courseDataList.value = pySegSort(res.body);
        }
      });
    };
    // 获取课程
    const getCurrentCourse = (arr) => {
      currentChoseCourseList.value = arr;
      currentParamsList.currentChoseCourse = arr;
    };
    const deleteProject = (item) => {
      currentChoseCourseList.value = currentChoseCourseList.value.filter((ele) => {
        return ele.id !== item.id;
      });
      currentParamsList.currentChoseCourse = currentChoseCourseList.value;
      // 找到当前课程数组改变选中值
      courseDataList.value.forEach((ele) => {
        if (ele.children.length) {
          ele.children.forEach((n) => {
            if (item.id === n.id) {
              n.checked = false;
            }
          });
        }
      });
    };
    // 监听专业该表获取相关课程
    watch(getCurrentProfessionalList, (val) => {
      if (val.length) {
        let id = val[0].id;
        getAppMajorAllCourseList(id);
      } else {
        courseDataList.value = [];
        currentChoseCourseList.value = [];
        state.major = "";
        // context.emit('getAppBusiness',true)
      }
    });
    // 监听商品类型改变，获取新值
    watch(currentGoodsType, () => {
      getCurrentTypeList();
    });
    // 监听省份
    watch(getCurrentProvinceList, (val) => {
      if (val.length) {
        if (val[0].children && val[0].children.length) {
          professionalList.value = pySegSort(val[0].children);
        }
      }
    });
    // 监听参数是否可以提交
    watch(
      currentParamsList,
      (val) => {
        if (
          val.getProfessList &&
          val.getProfessList.length &&
          val.currentMajor &&
          val.step
        ) {
          if (val.currentMajor === 1) {
            context.emit("getAppBusiness", false);
          } else {
            if (val.currentChoseCourse && val.currentChoseCourse.length) {
              context.emit("getAppBusiness", false);
            } else {
              context.emit("getAppBusiness", true);
            }
          }
        } else {
          context.emit("getAppBusiness", true);
        }
      },
      { deep: true }
    );
    const getCurrentTypeList = () => {
      businessTreesList.value.forEach((item) => {
        if (currentGoodsType.value == item.id) {
          if (currentGoodsType.value != 3 && currentGoodsType.value != 4) {
            provinceList.value = pySegSort(item.children);
          } else {
            professionalList.value = pySegSort(item.children);
          }
        }
      });
    };
    // 返回拼接参数
    const retrunReportMessage = () => {
      let curoseString = "";
      currentChoseCourseList.value.forEach((item) => {
        curoseString += item.name;
      });
      if (state.majorType === 2) {
        return `${curoseString}·单门课程`;
      } else {
        return `${state.major}·整专业`;
      }
    };
    // 返回当前参数
    const retrunCurrentParams = () => {
      let goods = [];
      let description = "";
      if (currentParamsList.currentMajor === 1) {
        goods = [getCurrentProfessionalList.value[0].id];
      } else {
        goods = currentParamsList.currentChoseCourse.map((item) => {
          return item.id;
        });
      }
      if (currentParamsList.currentMajor === 1) {
        description = `${getCurrentProfessionalList.value[0].name}·整专业`;
      } else {
        description =
          currentParamsList.currentChoseCourse.map((item) => item.name).join(",") +
          `${currentParamsList.currentChoseCourse.length}门课程`;
      }
      return {
        customerId: localStorage.getItem("customerId"),
        description: description,
        goodsNoList: goods,
        signType: currentParamsList.currentMajor === 2 ? 1 : currentGoodsType.value,
        status: currentParamsList.step,
        successPercent: Number(progress.value.getCurrentNumber().value),
        type: 2,
      };
    };
    return {
      pattern,
      statusList,
      goodTypeLists,
      getGoodsType,
      courseLists,
      provinceList,
      professionalList,
      getMajorType,
      getCurrntStep,
      progress,
      retrunReportMessage,
      currentGoodsType,
      getCurrentProfessional,
      ...toRefs(modelShowList),
      goodsBigTypeLists,
      deleteProject,
      courseDataList,
      getCurrentCourse,
      currentChoseCourseList,
      retrunCurrentParams,
      getCurrentProvinceSelect,
      state,
    };
  },
};
</script>
<style lang="less" scoped>
.examination-business {
  margin-top: 48px;
  .choose-good {
    background: #fff;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 24px;
    overflow: hidden;
    & > div:last-child {
      border: none;
    }
    .step-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .business-odds {
      width: 100%;
    }
  }
  div.add-major {
    &::after {
      border-bottom: 1px solid #fff;
    }
  }
  .add-project-wrapper {
    display: flex;
    flex-wrap: wrap;
    .project-item {
      padding: 8px 24px;
      background: rgba(22, 120, 255, 0.1);
      color: #1678ff;
      font-size: 24px;
      border-radius: 30px;
      margin-bottom: 20px;
      // &:nth-child(n + 2) {
      //   margin-left: 20px;
      // }
      margin-right: 20px;
    }
  }
}
</style>
